import React, { useState, useEffect } from 'react';
import { Input, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import useDebounce from '../../../../utils/debounce/useDebounce';

const { Form } = ANTD_COMPONENTS;

const StringAnswer = (props) => {
  const { label, onChange, value = '', debounceDelay = 1000 } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, []);

  const debounce = useDebounce((data) => {
    console.log('deb', data);
    onChange(data); // Передаём валидное значение
  }, debounceDelay);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    debounce(e.target.value); // Вызываем debounce
  };

  return (
    <Input
      name={label}
      placeholder={label}
      style={{ padding: '10px' }}
      onChange={handleChange}
      value={inputValue}
    />
  );
};

export default StringAnswer;
