import React, { useState } from 'react';
import styles from './index.module.scss';
import { InputTextArea } from 'tt-ui-lib/core';
import useDebounce from '../../../../utils/debounce/useDebounce';

const TextAreaAnswer = (props) => {
  const { onChange, value = '', placeholder, debounceDelay = 300 } = props;
  const [inputValue, setInputValue] = useState(value);

  const debounce = useDebounce((data) => {
    onChange(data); // Передаём валидное значение
  }, debounceDelay);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    debounce(e.target.value); // Вызываем debounce
  };

  return (
    <div className={styles.stringAnswer}>
      <InputTextArea
        style={{ padding: '10px' }}
        inputMode="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={inputValue}
      />
    </div>
  );
};

export default TextAreaAnswer;
