const ttMainHost = process.env.REACT_APP_SSO_HOST;
const ttMarketHost = process.env.REACT_APP_MARKET;

export const NumberFilter = /^((\d+[,|.])?(\d+)?)$/;

export const CUSTOM_REPORT_STATES = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_DATA: 'NO_DATA',
  NONE: 'NONE',
};

export const CALC_TYPES = {
  CUSTOM_REPORT: 'custom_report',
  CUSTOM_FILES: 'custom_files',
  SUST_CSRD_ESRD: 'sust_csrd_esrd',
  SUST_GRI: 'sust_gri',
  SUST_ISSB: 'sust_issb',
  SUST_CDP: 'sust_cdp',
  SUST_GHG: 'ghg_report',
};

export const GetTypesAsArray = () => Object.keys(CALC_TYPES).map((key) => CALC_TYPES[key]);

export const CalculatorsListData = Object.freeze({
  [CALC_TYPES.SUST_GRI]: {
    order: 0,
    label: 'GRI sustainability reporting',
    children: [CALC_TYPES.SUST_GRI],
    info: `<p>The GRI sustainability reporting standards cover topics ranging from biodiversity to tax,
    waste to emissions, diversity and equality to health and safety; and provide disclosure of both positive
    and negative impacts on the environment, society and the economy. The GRI standards are widely accepted
    and may be used by companies of all sizes, forms of ownership, organizational structures and industrial affiliations.
    </p>`,
    requiredFields: {
      stringFields: ['name'],
      dateFields: ['periodReportDate'],
    },
    isSingleInYear: false,
  },
  [CALC_TYPES.CUSTOM_REPORT]: {
    order: 1,
    label: 'Customizable reporting (step-by-step)',
    children: [CALC_TYPES.CUSTOM_REPORT],
    info: `<p>Form Completion Guidelines:</p>
    <p>Complete the form below by entering information in all fields. Add new fields for additional questions as needed. The more precise and detailed questions you enter, the more accurate answers you will receive.
    You can stop entering data at any time. Your draft will be saved and available in subsection "Drafts" of "Score &Reporting" section of your profile. You can return to the draft for review and edit as many times as you wish. 
    When finish click "generate answers" button at the end of the page.
    If you wish to edit the data you entered, you can do so by clicking "edit" button.  When finish editing click "generate answers" at the end of the page.
    </p>`,
    requiredFields: {
      stringFields: ['name'],
      dateFields: ['periodReportDate'],
    },
    isSingleInYear: false,
  },
  [CALC_TYPES.CUSTOM_FILES]: {
    order: 2,
    label: 'Customizable reporting (massive upload)',
    children: [CALC_TYPES.CUSTOM_FILES],
    info: `<p>Form Completion Guidelines:</p>
    <p>Complete the form below by entering information in all fields. Add new fields for additional questions as needed. The more precise and detailed questions you enter, the more accurate answers you will receive.
    You can stop entering data at any time. Your draft will be saved and available in subsection "Drafts" of "Score &Reporting" section of your profile. You can return to the draft for review and edit as many times as you wish. 
    When finish click "generate answers" button at the end of the page.
    If you wish to edit the data you entered, you can do so by clicking "edit" button.  When finish editing click "generate answers" at the end of the page.
    </p>`,
    requiredFields: {
      stringFields: ['name'],
      dateFields: ['periodReportDate'],
    },
    isSingleInYear: false,
  },
  [CALC_TYPES.SUST_GHG]: {
    order: 3,
    label: 'GHG emissions report',
    children: [CALC_TYPES.SUST_GHG],
    info: `<p>Our GHG emissions report takes into account the Greenhouse Gas Protocol recommendations for corporate reporting and covers emissions from the company's
    own operations (Scope 1 & Scope 2 emissions) and from the corporate value chain (Scope 3). The report also provides details on the company's strategy
    and initiatives in the field of GHG emissions and their reduction.
    </p>`,
    requiredFields: {
      stringFields: ['name'],
      dateFields: ['periodReportDate'],
    },
    isSingleInYear: false,
  },
});

export const CalculatorsUrls = Object.freeze({
  [CALC_TYPES.CUSTOM_REPORT]: '/report/custom_report/1',
  [CALC_TYPES.CUSTOM_FILES]: '/report/custom_files',
  [CALC_TYPES.SUST_GRI]: '/report/sust_gri',
  [CALC_TYPES.SUST_GHG]: '/report/ghg_report',
});

export const SelfAssessmentInfoText = Object.freeze({
  COMMON: `
    <p>
      The TransparenTerra Platform facilitates your sustainability reporting, corporate sustainability indicators, and overall sustainability performance in a personalized and efficient way.
    </p>
    <p>
      With Customisable Reporting solution, you can easily create the reporting framework to measure your sustainability performance aligned to your industry and your sector. Once you've completed the custom reporting framework, you can also share it and get feedback and guidance from TransparenTerra's community of experts, who have extensive experience and knowledge in sustainability reporting. Choose a convenient way to enter your information, upload the data and information you need, and get personalized results.
    </p>
    <p>
      We are here to support you on your sustainability journey and help you achieve your goals with confidence and clarity. We help you turn confusing data into clear numbers and concrete results.
    <p>
  `,
});

export const SelfAssessmentDisclaimerText = Object.freeze({
  COMMON: `info`,
  [CALC_TYPES.CUSTOM_REPORT]: `
    <h4>
      Please read this text to the end
    </h4>
    <p>
      The reports on TransparenTerra are generated automatically by
      an&nbsp;AI&nbsp;language model that has been trained on a&nbsp;vast
      range of&nbsp;data. The&nbsp;AI-generated content is&nbsp;intended
      to&nbsp;be informative and helpful however it should not be considered
      a&nbsp;substitute for professional advice. We encourage you to seek
      an&nbsp;expert opinion before making decisions based on this content. 
    </p>
  `,
  [CALC_TYPES.CUSTOM_FILES]: `
    <h4>
      Please read this text to the end
    </h4>
    <p>
      The reports on TransparenTerra are generated automatically by
      an&nbsp;AI&nbsp;language model that has been trained on a&nbsp;vast
      range of&nbsp;data. The&nbsp;AI-generated content is&nbsp;intended
      to&nbsp;be informative and helpful however it should not be considered
      a&nbsp;substitute for professional advice. We encourage you to seek
      an&nbsp;expert opinion before making decisions based on this content. 
    </p>
  `,
});

export const Menu = [
  {
    name: 'About',
    url: '/about',
    order: 5,
  },
];

export const AddMenu = [
  {
    name: 'Offset Credits',
    url: '/offset-credits',
    order: 6,
    notForIndiv: true,
  },
];

export const UserMenu = [
  {
    name: 'Profile',
    url: '/profile',
    order: 1,
  },
  {
    name: 'New assessment',
    url: '/newassessment',
    order: 2,
    notForIndiv: true,
  },
  {
    name: 'Completed assessments',
    url: '/completed',
    order: 3,
    notForIndiv: true,
  },
  {
    name: 'Drafts',
    url: '/drafts',
    order: 4,
    notForIndiv: true,
  },
  { name: 'Help', url: '', disabled: true },
  {
    name: 'Continue editing',
    url: '/editCalculator',
    order: 7,
  },
  { name: 'hr', order: 8 },
  { name: 'Log out', order: 9, url: '/logout' },
  // { name: "Finish", url: "/finish" },
];

export const ExternalUrl = [
  {
    url: '/profile',
    externalUrl: `${ttMainHost}/dashboard/profile/`,
  },
  {
    url: '/newassessment',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/customizable_reporting/newassessment`,
  },
  {
    url: '/completed',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/customizable_reporting/reports`,
  },
  {
    url: '/drafts',
    externalUrl: `${ttMainHost}/dashboard/score_and_reporting/menu/customizable_reporting/drafts`,
  },
  {
    url: '/buy_credits',
    externalUrl: `${ttMainHost}/dashboard/buy_credits/`,
  },
  {
    url: '/login',
    externalUrl: `${ttMainHost}/dashboard/feed`,
  },
  {
    url: '/carbon_credits',
    externalUrl: `${ttMarketHost}/dashboard/marketplace/list/product?activeTab=carbon`,
  },
  {
    url: '/',
    externalUrl: `${ttMainHost}/`,
  },
];

export const acceptedExtentions = {
  COMMON: {
    acceptedImages: ['jpg', 'jpeg', 'png', 'tif', 'tiff'],
    acceptedDocs: ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'csv'],
  },
  [CALC_TYPES.CUSTOM_REPORT]: {
    acceptedDocs: ['doc', 'docx', 'xls', 'xlsx', 'csv', 'xml', 'txt', 'pdf'],
  },
  [CALC_TYPES.CUSTOM_FILES]: {
    acceptedDocs: ['doc', 'docx', 'pdf', 'xlsx', 'xls', 'csv', 'txt'],
  },
};
