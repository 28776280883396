import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './GhgReport.module.scss';
import { ANTD_COMPONENTS, Button } from 'tt-ui-lib/core';
import { ANTD_ICONS } from 'tt-ui-lib/icons';
import {
  getGhgQuestionsByPage,
  getDraftById,
  setGhgAnswer,
  getGhgAnswers,
} from '../../../api/rest/list';
import { ErrorContext, UserContext } from '../../../context';
import GhgQuestion from './components/GhgQuestion';
import NavCollapse from '../../../components/navCollapse/NavCollapse';
import useDebounce from '../../../utils/debounce/useDebounce';

const { Row, Col } = ANTD_COMPONENTS;

const GhgReport = () => {
  const { pathname } = useLocation();
  const { pageNum } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [navData, setNavData] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [isNeedSave, setIsNeedSave] = useState(false);
  const [questionNotExists, setQuestionNotExists] = useState(false);
  const { draftId, user } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const saveAnswers = () => {
    setGhgAnswer({
      report_id: reportId,
      data: {
        questions: [
          ...Object.keys(answers).map((key) => ({
            id: key,
            answer_text: JSON.stringify(answers[key]),
          })),
        ],
      },
    }).then((resp) => {
      const { data, success } = resp.data;
      console.log('save GhgAnswers', { data, success, resp });
    });
  };

  useEffect(() => {
    if (questionNotExists) navigate('/404');
  }, [questionNotExists]);

  useEffect(() => {
    console.log('pageNum', pageNum);
    if (Number.isNaN(pageNum) || (questions && questions.length < parseInt(pageNum, 10))) {
      console.log({
        f: Number.isNaN(pageNum),
        s: questions && questions.length < parseInt(pageNum, 10),
      });
      setQuestionNotExists(true);
    }
  }, [pageNum, questions]);

  useEffect(() => {
    if (!questions) return;

    const navigationData = questions.reduce((acc, question, index) => {
      const { topic: topicName } = question;
      if (!acc.some((t) => t.label === topicName)) {
        acc.push({ key: topicName, label: topicName, children: [] });
      }
      acc
        .find((t) => t.label === topicName)
        .children.push({
          key: question.questionText,
          label: question.questionText,
          link: index + 1,
          icon: null,
        });
      return acc;
    }, []);

    console.log(navigationData);
    setNavData(navigationData);
  }, [questions]);

  useEffect(() => {
    console.log('GhgReport answers', answers);
  }, [answers]);

  useEffect(() => {
    if (!draftId) return;
    getDraftById(draftId)
      .then((resp) => {
        const { data: draft, success } = resp?.data ?? {};
        if (success) {
          setReportId(draft?.reportId);
        }
      })
      .catch((err) => {
        setErrorAlert('Cannot load the draft, please thy again later', err);
      });
  }, [draftId]);

  useEffect(() => {
    if (reportId) {
      getGhgQuestionsByPage({ report_id: reportId }).then((resp) => {
        const { data, success } = resp.data;
        if (success) {
          setQuestions(data);
        }
      });
    }
  }, [reportId]);

  useEffect(() => {
    if (answers && isNeedSave && reportId) {
      saveAnswers();
    }
  }, [isNeedSave, answers]);

  // получаем сохраненные ответы
  useEffect(() => {
    if (reportId) {
      getGhgAnswers({ report_id: reportId }).then((resp) => {
        const { data, success } = resp.data;
        console.log('get GhgAnswers', { data, success, resp });
        if (success) {
          const newAnswers = data.reduce(
            (acc, q) => ({
              ...acc,
              [q.ghgQuestionId]: JSON.parse(q.answerText),
            }),
            {}
          );
          setAnswers(newAnswers);
        }
      });
    }
  }, [reportId]);

  useEffect(() => {
    console.log('isNeedSave', isNeedSave);
  }, [isNeedSave]);

  const debounce = useDebounce(() => {
    setIsNeedSave(true); // Передаём валидное значение
  }, 3000);

  const onNavigationClick = (value) => {
    console.log(value);
    navigate(pathname.replace(pageNum, parseInt(value, 10)));
  };

  const onNextClick = () => {
    if (!questions) return;
    if (pageNum < questions.length - 1) {
      navigate(pathname.replace(pageNum, parseInt(pageNum, 10) + 1));
    }
  };

  const onBackClick = () => {
    if (pageNum > 0) {
      navigate(pathname.replace(pageNum, parseInt(pageNum, 10) - 1));
    }
  };

  const onFinishClick = async () => {
    await saveAnswers();
    navigate('/drafts');
  };

  const onAnswersChange = (data, questionId) => {
    console.log('GhgReport onAnswersChange', { data, questionId });
    setAnswers((prev) => ({ ...prev, [questionId]: data }));
    debounce();
  };

  return (
    <>
      <div className={styles.main}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }} style={{ height: '100%', width: '100%' }}>
          <Col xs={24} sm={24} md={7} style={{ height: '100%', width: '100%' }}>
            <div className={styles.navigator}>
              <div className={styles.scrollable}>
                {navData && (
                  <NavCollapse
                    data={navData}
                    onNavigationClick={onNavigationClick}
                    currentLink={parseInt(pageNum, 10)}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={17} style={{ height: '100%', width: '100%' }}>
            <div className={styles.mainPanel}>
              <div className={styles.questionWrapper}>
                <div className={styles.scrollable}>
                  {answers && questions && (
                    <GhgQuestion
                      question={questions?.[pageNum - 1]}
                      questionAnswers={answers[questions?.[pageNum - 1]?.id]}
                      setQuestionAnswers={(value) =>
                        onAnswersChange(value, questions?.[pageNum - 1]?.id)
                      }
                    />
                  )}
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <Button className={styles.button} onClick={onBackClick}>
                  Back
                </Button>
                {questions?.length > parseInt(pageNum, 10) && (
                  <Button className={styles.button} onClick={onNextClick}>
                    Next
                  </Button>
                )}
                {questions?.length === parseInt(pageNum, 10) && (
                  <Button className={styles.button} onClick={onFinishClick}>
                    Finish
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

GhgReport.propTypes = {};

export default GhgReport;
