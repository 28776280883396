import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_GHG_REPORT_QUESTIONS_GET_URL,
  API_GHG_REPORT_ANSWERS_GET_URL,
  API_GHG_REPORT_ANSWER_SET_URL,
  API_GHG_REPORT_DELETE_URL,
} from './urls';

export const getGhgQuestionsByPage = async (params) =>
  axiosClient.get(API_GHG_REPORT_QUESTIONS_GET_URL, axiosSSOConfigPrivate({}, { params: params }));

export const getGhgAnswers = async (params) =>
  axiosClient.get(API_GHG_REPORT_ANSWERS_GET_URL, axiosSSOConfigPrivate({}, { params: params }));

export const deleteGhgReport = async (params) =>
  axiosClient.get(API_GHG_REPORT_DELETE_URL, axiosSSOConfigPrivate({}, { params: params }));

export const setGhgAnswer = async (params) =>
  axiosClient.post(API_GHG_REPORT_ANSWER_SET_URL, params, axiosSSOConfigPrivate());
