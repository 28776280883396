import React, { useEffect } from 'react';
import { Form, Input, Button, Select } from 'tt-ui-lib/core';
import TextAreaAnswer from './TextAreaAnswer';
import StringAnswer from './StringAnswer';
import styles from './index.module.scss';
import { MinusCircleOutlined } from '@ant-design/icons';

const GhgQuestion = ({ question, setQuestionAnswers, questionAnswers }) => {
  const [form] = Form.useForm();

  useEffect(() => console.log(form.getFieldValue('answers')), [form]);

  const { id, type } = question ?? {};

  // Сбрасываем форму и инициализируем новые значения при изменении входящих данных
  useEffect(() => {
    if (!id) return;
    console.log('questionAnswers:', questionAnswers);

    const initialData = type === 'string' ? '' : [{}];
    form.resetFields();
    if (questionAnswers) {
      console.log('load', questionAnswers);
      form.setFieldsValue({ answers: questionAnswers });
    } else {
      console.log('new', { initialData, question, type });
      form.setFieldsValue({ answers: initialData }); // Один набор полей по умолчанию
    }
  }, [id]);

  const handleFieldChange = (fieldPath, value) => {
    form.setFieldValue(fieldPath, value);
    // Валидация конкретного поля
    form
      .validateFields()
      .then(() => {
        // Если успешно, обновляем состояние
        const updatedAnswers = form.getFieldValue('answers');
        setQuestionAnswers(updatedAnswers);
      })
      .catch((err) => {
        console.log('Validation failed:', err);
      });
  };

  useEffect(() => console.log('form', form), [form]);

  const renderQuestionFields = () => {
    if (question.type === 'string') {
      return (
        <Form.Item name="answers">
          <TextAreaAnswer
            placeholder="Type your answer here..."
            onChange={(value) => handleFieldChange('answers', value)}
          />
        </Form.Item>
      );
    }

    if (question.type === 'multiple' && question.fields) {
      console.log('render question', question);
      return (
        <Form.List name="answers">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, rowIndex) => (
                <div key={field.key} className={styles.answerRow}>
                  {Object.keys(question.fields).map((key) => {
                    const fieldConfig = question.fields[key];
                    const inputType = fieldConfig.variants ? 'select' : 'input';
                    const validation = fieldConfig.validation?.replace(/\//g, '');

                    return (
                      <Form.Item
                        key={key}
                        name={[field.name, key]}
                        rules={[
                          { max: 255, message: 'Too many characters.' },
                          validation && {
                            pattern: new RegExp(validation),
                            message: 'Invalid format.',
                          },
                        ]}
                        className={styles.item}
                      >
                        {inputType === 'input' && (
                          <StringAnswer
                            label={key}
                            onChange={(value) => handleFieldChange(field.name, key, value)}
                          />
                        )}
                        {inputType === 'select' && (
                          <Select
                            label={key}
                            onChange={(value) => handleFieldChange(field.name, key, value)}
                            options={
                              fieldConfig.variants?.map((element) => ({
                                label: element,
                                value: element,
                              })) || []
                            }
                          />
                        )}
                      </Form.Item>
                    );
                  })}
                  <Button
                    type="icon"
                    className={styles.icon}
                    danger
                    onClick={() => remove(field.name)}
                    style={{ height: 'unset', margin: 'auto 0' }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                  {rowIndex === fields.length - 1 && (
                    <Button style={{ marginLeft: 'auto' }} type="link" onClick={() => add()} block>
                      Add Data
                    </Button>
                  )}
                </div>
              ))}
              {!fields?.length && (
                <Button style={{ marginLeft: 'auto' }} type="link" onClick={() => add()} block>
                  Add Data
                </Button>
              )}
            </>
          )}
        </Form.List>
      );
    }

    return null;
  };

  return (
    <Form form={form} className={styles.ghgForm}>
      {question && (
        <div className={styles.ghgQuestion}>
          <h2 className={styles.title}>{question.questionText}</h2>
          {renderQuestionFields()}
        </div>
      )}
    </Form>
  );
};

export default GhgQuestion;
