import React from 'react';
import { Collapse, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import clsx from 'clsx';
import styles from './NavCollapse.module.scss'; // Предполагается, что стили уже созданы
import { useLocation } from 'react-router-dom';
import { ReactComponent as UpIco } from '../../assets/icons/expandUp.svg';
import { ReactComponent as DownIco } from '../../assets/icons/expandDown.svg';

/*
const NavItem = {
  key: string;
  label: string;
  link?: string;
  icon?: React.ReactNode;
  children?: [NavItem];
};

const data = [NavItem];
*/

const NavCollapse = ({ data, onNavigationClick, currentLink, panelStyle }) => {
  const getIcon = ({ isActive }) =>
    isActive ? <UpIco style={{ width: '30px' }} /> : <DownIco style={{ width: '30px' }} />;

  const getItems = (items, isTopLevel = false) =>
    items.map((item) => {
      // Разделение детей на ссылки и панели
      const childrenWithPanels = item.children?.filter((child) => child.children) || [];
      const childrenWithLinks = item.children?.filter((child) => child.link) || [];

      const isSelected = childrenWithLinks?.some((child) => child.link === currentLink);

      const hasPanels = childrenWithPanels.length > 0;
      const hasLinks = childrenWithLinks.length > 0;

      return {
        key: item.key,
        label: (
          <span className={clsx(styles.collapseText, isSelected && styles.selected)}>
            {item.label}
          </span>
        ),
        children: (
          <div className={styles.childrenContainer}>
            {/* Сначала рендерим вложенные панели */}
            {hasPanels && (
              <Collapse
                items={getItems(childrenWithPanels)}
                className={styles.nestedCollapse}
                bordered={false}
                expandIcon={getIcon}
              />
            )}
            {/* Затем добавляем ссылки */}
            {hasLinks && (
              <div className={styles.linkListContainer}>
                {childrenWithLinks.map((link) => (
                  <div key={link.key} className={styles.linkItem}>
                    <button
                      type="button"
                      onClick={() => onNavigationClick(link.link)}
                      className={clsx(
                        styles.linkButton,
                        currentLink === link.link && styles.selected
                      )}
                    >
                      {link.label}
                    </button>
                    <div className={styles.icon}>{link.icon}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ),
        className: clsx(
          styles.panelStyle,
          isSelected && styles.selected,
          isTopLevel && styles.topLevel
        ),
        style: { panelStyle },
      };
    });

  return <Collapse items={getItems(data, true)} bordered={false} expandIcon={getIcon} />;
};

export default NavCollapse;
