import { useEffect, useRef } from 'react';

const useDebounce = (callback, delay) => {
  const handler = useRef();

  const debounce = (...args) => {
    console.log('useDebounce', { ...args });
    if (handler.current) clearTimeout(handler.current);
    handler.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(
    () => () => {
      if (handler.current) clearTimeout(handler.current);
    },
    []
  );

  return debounce;
};

export default useDebounce;
