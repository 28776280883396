import Cookies from 'js-cookie';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Button } from 'tt-ui-kit';
import { Grid } from '@mui/material';
import Form from '../../../components/form/Form';
import styles from './CustomFiles.module.scss';
import FileUploader from '../../../components/fileUploader/FileUploader';
import { ErrorContext, UserContext } from '../../../context';
import { CUSTOM_REPORT_STATES } from '../../../constants';
import FilesStateList from '../../../components/filesStateList/FilesStateList';
import { getCustomFiles, getDraftById } from '../../../api/rest/list';

const ttRestHost = process.env.REACT_APP_REST_API;

const CustomFiles = () => {
  const { draftId, user } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);
  const navigate = useNavigate();

  const [questionFilesList, setQuestionFilesList] = useState([]);
  const [reportId, setReportId] = useState([]);
  const [dataFilesList, setDataFilesList] = useState([]);
  const [generateModalOpen, setGenerateModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);

  const onBackClick = () => navigate('/registration');

  const loadFiles = () => {
    getCustomFiles({ id: reportId })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { customFilesQuestion, customFilesData } = data;
          setQuestionFilesList(customFilesQuestion);
          setDataFilesList(customFilesData);
        }
      })
      .catch((err) => {
        setErrorAlert('Error loading files. Please try again later.', err);
      })
      .finally(() => setNeedRefresh(false));
  };

  const getDraftData = () => {
    getDraftById(draftId)
      .then((resp) => {
        const { data: newDraft, success } = resp.data;
        if (success && newDraft) {
          setReportId(newDraft.reportId);
        }
      })
      .catch((err) => {
        setErrorAlert('Draft loading error', err);
      });
  };

  const onCloseCallback = () => {
    setNeedRefresh(true);
  };

  useEffect(() => {
    if (needRefresh) loadFiles();
  }, [needRefresh]);

  useEffect(() => {
    if (reportId) {
      loadFiles();
    }
    return () => {
      setQuestionFilesList([]);
      setDataFilesList([]);
    };
  }, [reportId, isProcessing]);

  useEffect(() => {
    if (draftId) {
      getDraftData();
    }
  }, [draftId]);

  const generateReport = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const params = {
      source_id: reportId,
      source: 'custom_report_file',
    };
    const url = `${ttRestHost}/tt-ai/reports/generate`;

    axios
      .get(url, { headers, params })
      .then((response) => {
        const { data } = response;
        if (data?.success) {
          setIsProcessing(data?.success);
          setGenerateModalOpen(false);
        }
      })
      .catch((err) => {
        setErrorAlert('Error generating report. Please try again later.', err);
      });
  };

  const onGenModalClose = () => {
    setGenerateModalOpen(false);
  };

  const goToAssessment = () => {
    navigate('/completed');
  };

  return (
    <>
      <Modal
        open={generateModalOpen}
        onClose={onGenModalClose}
        onClick={(e) => e.stopPropagation()}
        title="Are you sure you are ready to start generating answers?file"
        closeOnlyByControls
      >
        <div style={{ padding: '16px 0 20px 0' }}>
          {`Please check the questions and answers before starting report
          generation. Only successfully processed data will be included in the report.`}
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={onGenModalClose}>
            Cancel
          </Button>
          <Button type="primary" onClick={generateReport}>
            Generate
          </Button>
        </div>
      </Modal>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.formContainer}
      >
        <Form>
          <div className={styles.form}>
            <div className={styles.title}>Upload file with questions</div>
            <div className={styles.description}>
              Please upload the document with the list of questions. (.doc, .docx, .pdf, .xlsx,
              .xls)
            </div>
            {questionFilesList.some((f) => !!f.state && f.state !== CUSTOM_REPORT_STATES.NONE) ? (
              <FilesStateList questionCode="custom_files_question" text="FIle with questions" />
            ) : (
              <FileUploader
                questionCode="custom_files_question"
                btnText="Upload question file"
                btnType="default"
                title="Upload file with questions"
                isMultiple={false}
                disabled={isProcessing}
              />
            )}
            <div className={styles.title}>Upload documents</div>
            <div className={styles.description}>
              Please upload all documents required to compile correct answers. (.doc, .docx, .pdf,
              .xlsx, .xls)
            </div>
            {dataFilesList.some((f) => !!f.state && f.state !== CUSTOM_REPORT_STATES.NONE) ? (
              <FilesStateList
                questionCode="custom_files_data"
                text="FIles with data for answers"
                disabled={isProcessing}
              />
            ) : (
              <FileUploader
                questionCode="custom_files_data"
                btnText="Upload documents"
                btnType="default"
                title="Upload documents"
                disabled={isProcessing}
                onCloseCallback={onCloseCallback}
              />
            )}
          </div>
        </Form>
        <div className={styles.navigation}>
          <Button type="default" onClick={onBackClick}>
            Back
          </Button>
          {!isProcessing && (
            <Button
              type="primary"
              onClick={() => setGenerateModalOpen(true)}
              disabled={questionFilesList.length === 0 || dataFilesList.length === 0}
            >
              Generate answer
            </Button>
          )}
          {isProcessing && (
            <Button type="primary" onClick={goToAssessment}>
              Go to assessments
            </Button>
          )}
        </div>
      </Grid>
    </>
  );
};

PropTypes.CustomFiles = {};

export default CustomFiles;
